import React from "react"
import Helmet from "react-helmet";
import Layout from "../components/layout.js"
import Wrapper from "../components/wrapper.js"
import ContactModal from "../components/contact-modal.js";
import HeaderSimple from "../components/header-simple";
import Footer from "../components/sections/footer.js"
import "../assets/css/bootstrap.min.css";
import "../assets/css/themify-icons.css";
import "../assets/css/flaticon-set.css";
import "../assets/css/magnific-popup.css";
import "../assets/css/owl.carousel.min.css";
import "../assets/css/owl.theme.default.min.css";
import "../assets/css/animate.css";
import "../assets/css/bootsnav.css";
import "../assets/css/responsive.css";
import "../styles/index.scss";
import store from "../state/createStore";
import { Provider } from "react-redux";
const TermsAndConditions = () => (
    <Provider store={store()}>
        <Layout>
                <Helmet>
                    <title>SOMMER - medical consultants for software development businesses</title>
                    <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="description" content="SOMMER - medical consultants for software development businesses" />
                    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800&display=swap" rel="stylesheet" />
                </Helmet>
                <Wrapper>
                    <ContactModal formType="doctor" />
                    <HeaderSimple />
                    <div className="banner-area banner-area-short text-default bg-cover shadow dark text-light">

                    </div>
                    {/* End Shape*/}
                    <div className="default-padding overflow-hidden">
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-lg-8 offset-lg-2">
                                <h1>Privacy Policy</h1>
                                
                                <p><strong>WHAT PERSONAL DATA DO WE COLLECT, AND HOW?</strong></p>
                                <p>When you contact us through our website, we receive and store your contact information, certain information related to your device, such as your device’s IP address, and cookies.<br /></p>
                                <p>01 	CONTACT AND PROFILE DETAILS</p>
                                <p>Some pages on our website have contact forms. Whether you apply for a job, register for one of our educational events or just leave a message, we ask for some basic information about you. This may include:</p>
                                <ul><li>Personal data: name, last name, location</li><li>Contact details: email, telephone number, Skype</li><li>Links to social profiles that you have made publicly accessible (LinkedIn, Facebook, DOU)</li><li>CV, professional qualifications, references<br /></li></ul>
                                <p>When we receive that data and any file submitted, we ask for your consent in order for us to store your data in our CRM so that we can contact you when we have an opportunity for you or respond to your queries, or provide the services and information that you requested or any information that is deemed relevant to your initial request.<br /></p>
                                <p>02 	LOG FILES</p>
                                <p>Within the webserver access logs, we may also collect your IP address, page visited, date and time, server response, and response size.<br /></p>
                                <p>03 	COOKIES AND ACCESS</p>
                                <p>When you visit our website, cookies are tracked. Cookies are small pieces of data that are stored by a website through your browser to enable an improved experience whilst using or browsing a particular website/app or to remember your preferences or navigation history/activity (such as pages you have visited within a particular site or typical activity) at a particular site. These cookies do not capture personally identifiable information (PII) and often include an anonymous unique identifier.</p>
                                <p>If you still don’t want your behavior to be tracked, you may disable cookies in your browser through the browser settings. You can also install the Google Analytics opt-out in your browser to prohibit Google Analytics JavaScript from sending information to Google Analytics <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">here</a>.</p>
                                <p>However, some website and app features or services may not function properly without cookies, and you will need to reset your preferences each time you log in. Please follow the links to further educate yourself on how to manage cookies: <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">Chrome</a>, <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&amp;redirectslug=enable-and-disable-cookies-website-preferences" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">Firefox</a>, <a href="https://support.microsoft.com/en-my/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">Edge</a>, <a href="https://support.microsoft.com/en-my/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">Internet Explorer</a>, <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">Safari</a>.<br /></p>
                                <p>WHY DO WE COLLECT THIS INFORMATION?</p>
                                <p>We will use your information to communicate with you in relation to your inquiry. We will also send you service-related communication via email and we may use your telephone number to contact you regarding your account. If you are not our client, but you give us your consent, we may contact you occasionally to provide you with information about our services in the long term, unless you retract that consent.</p>
                                <p>The legal basis for the processing of your personal data is:</p>
                                <ul><li>Your consent to process data for a specific purpose, such as an inquiry or a consent to provide you with information about our services</li><li>For processing your job application or application for collaboration with us</li><li>For the performance of our contract with you</li><li>Legal obligations to which we are subject and</li><li>Our legitimate interest to promote our business balanced with your right to privacy.</li></ul>
                                <p>We may use cookies to remember your preferences when you have visited our site and pages you like to regularly visit and to ensure that you receive the most up-to-date information that is relevant to you and your experience with us. We also use cookies for internal management purposes of our website. We will not use these cookies to store information such as account details.</p>
                                <p>Our website access logs are only collected for tracking visits to our site for statistical and performance optimization purposes and to assist should any visitor, including you, not be able to access websites. We use this information as well to ensure the security and availability of our website.</p>
                                <p>We do not use the information you provide to make any automated decisions that might affect you.</p>
                                <p></p>
                                <p><strong>WHERE DO WE KEEP YOUR INFORMATION AND WHAT SECURITY MEASURES ARE IN PLACE?</strong><br /></p>
                                <p>Your personal information is stored in our online processing system and company information systems that are based within the US and Ukraine.</p>
                                <p>We use industry-standard data security, technical and organizational measures to protect your data. Along with providing a safe work environment, we use an encryption procedure to prevent misuse of data by third parties. Our data security is in accordance with current technology. We make every reasonable effort to protect your personal information. However, please do understand that whilst we do our best to protect your data, no organization is immune to data breaches.<br /></p>
                                <h3><strong>RECIPIENTS OF DATA</strong></h3>
                                <p>01 	WITHIN SOMMER.CONSULTING</p>
                                <p>Your data will be shared with the appropriate contractors within SOMMER.CONSULTING who need to access your data for the reasons of processing highlighted above. Appropriate agreements are in place with these contractors as requested by applicable laws such as the GDPR in order to ensure the security, confidentiality, and protection of your rights over your data.<br /></p>
                                <p>02 	SERVICE PROVIDERS</p>
                                <p>We may disclose your data to selected third-party vendors, service providers, contractors or agents who perform functions on our behalf, such as website management, hosting, technical support, and marketing analytics who treat the data protectively by having own privacy policies in place. Those service providers include, but are not limited, to:</p>
                                <ul><li> Content Management Systems (CMS) as necessary to serve website content</li><li>Email and marketing automation provider as necessary for sending emails to our  users</li><li>Providers of payment processing and accounting, as necessary to process payment  from our users</li><li>Customer Relationship Management (CRM) platform as necessary for sales lead  tracking</li><li>Providers of event registration systems and services as necessary for events</li><li>Providers of research and analytics services, including Google Analytics</li><li>Providers of Cloud computing infrastructure services</li><li>Internet service providers</li><li>Professional services providers necessary for technical support</li></ul>
                                <p></p>
                                <p>INTERNATIONAL TRANSFERS<br /></p>
                                <p>We process your personal data within our company premises based in the USA and Ukraine, both of which are outside of the European Economic Area and other countries deemed to have equivalent data protection safeguards in place.</p>
                                <p>This processing is done on the basis of it being necessary for the performance of the contract between us and, where there is not a contract in place, with your informed consent.<br /></p>
                                <p>HOW LONG DO WE KEEP YOUR INFORMATION FOR?<br /></p>
                                <p>Unless you have a contractual relationship with us, we keep your communication information for 10 years after communication for the purpose of being able to refer back to your communication request and respond to any follow-up queries. We will delete your personal data as soon as we no longer have a need or a legal basis to process it. Where possible, we will inform you how long this period will be at the point that you submit your information or once this is received.</p>
                                <p>If we do not have any contractual obligation in place, you may request that we dispose of your information at any time. Where possible, we will exercise your request and, if we are not able to, we will tell you the reason why and what steps we will take to restrict the processing of your data.</p>
                                <p>If you have a contractual relationship with us, any personal information provided to us will be retained as defined without our contractual relationship and after termination, where applicable, for the duration of any exercise of legal claims or to service our legal obligations such as company records obligations or tax obligations.<br /></p>
                                <p>YOUR RIGHTS OVER YOUR INFORMATION</p>
                                <p>According to European Union laws, in particular, the General Data Protection Regulation, you have the following rights regarding your personal data:</p>
                                <ul><li>The right to obtain any information about the processed personal data within one  month</li><li>The right to access your personal data</li><li>The right to correct or erase your personal data</li><li>The right to be aware of any correction or erasure of your personal data, restriction of  processing, and recipients of personal data</li><li>The right to object to the processing of your personal data</li><li>The right of data portability</li><li>The right not to be subject to a decision based solely on automated processing&nbsp;</li><li>The right to lodge a complaint with a data protection authority.</li></ul>
                                <p>You can also ask us to give you a copy of the information and to stop using your information for a period of time if you believe we are not doing so lawfully.</p>
                                <p>Please keep in mind that you can always withdraw your consent to process your personal data for marketing purposes by leaving us a clear note at contact@sommer.consulting at any time, or by clicking the Unsubscribe button at the footer of any email received from us.</p>
                                <p>Please also note that if you withdraw your consent for direct marketing by clicking the Unsubscribe button, we will not delete the rest of your data automatically but you may request that we do so by sending an email to the address above.</p>
                                <p>If you have a concern about our use of your information, you can contact your local data protection regulator, if you have one. A list of European data protection regulators can be found <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank" rel="noreferrer noopener" aria-label=" (opens in a new tab)">here</a>.<br /></p>
                            </div>
                        </div>
                    </div>
                    </div>
                    <Footer />
                </Wrapper>
        </Layout>
    </Provider>
)
export default TermsAndConditions